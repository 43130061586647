
	.teachingHangul
		user-select none
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)

		.flex
			display: flex
			flex-wrap: wrap
			flex-direction row
			align-items center
			align-content center
			justify-content: center
			-webkit-box-direction: normal
			-webkit-box-pack: center
			margin-bottom 60px
			div, img
				align-self: center

		.letter
			padding 30px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 60px
			height 60px
			margin-bottom 20px
		.soundOn
			height 20px
			width 20px
			padding 10px
			border 1px solid #fff
			border-radius: 50%
			display block
			cursor pointer
		.pronounce ::v-deep
			font-size: 24px
			margin-right 20px
			color #fff
			position relative
			span
				background #fff
				color #000
				padding 4px 10px
				font-size 1.2em
				display inline-block
			&:after
				content ""
				display block
				position absolute
				bottom -5px
				left 0
				width 100%
				height 1px
				background #fff

